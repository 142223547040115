import { Record, List } from 'immutable'

const SubBudgetRecord = Record({
  id: undefined,
  name: '',
  budgetId: undefined,
  companyCode: undefined,
  displayDecimalsAmount: 0,
  dimensionTarget: {
    dimensionValues: List(),
    id: undefined,
  },
  presentationType: null,
  status: undefined,
  customStatus: undefined,
})

export default SubBudgetRecord

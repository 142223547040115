import { Record } from 'immutable'

const BudgetRecord = Record({
  accountSchemeId: '',
  budgetType: 'Normal',
  endDate: null,
  id: undefined,
  isDefault: false,
  lockDate: null,
  name: '',
  companyCode: '',
  startDate: null,
  useAccountingLockDate: false,
  realBalanceEndDate: null,
  activeLockDate: null,
  presentationType: null,
  displayDecimalsAmount: null,
  timeRangeStart: null,
  timeRangeEnd: null,
  tree: null,
  budgetFolderIds: null,
  removeDimensionsFromBalanceSheet: false,
  dimensionIdWhitelist: null,
})

export default BudgetRecord

/*
 * ErrorComponent Messages
 *
 * This contains all the text for the ErrorComponent component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  aadInvitationError: {
    id: 'app.components.ErrorComponent.aadInvitationError',
    defaultMessage:
      'There is a problem in our email service. Sorry for inconvenience. Please, use your old credentials and try again later.',
  },
  aadInvitationErrorTitle: {
    id: 'app.components.ErrorComponent.aadInvitationErrorTitle',
    defaultMessage: 'Invitation Error',
  },
  aadInvitationProblemTitle: {
    id: 'app.components.ErrorComponent.aadInvitationProblemTitle',
    defaultMessage: 'Thank you',
  },
  continue: {
    id: 'app.components.ErrorComponent.continue',
    defaultMessage: 'Continue',
  },
  header: {
    id: 'app.components.ErrorComponent.header',
    defaultMessage: 'Error',
  },
  forbidden: {
    id: 'app.components.ErrorComponent.forbidden',
    defaultMessage: 'Forbidden',
  },
  internalServerError: {
    id: 'app.components.ErrorComponent.internalServerError',
    defaultMessage: 'Internal Server Error',
  },
  brokenReport: {
    id: 'app.components.ErrorComponent.brokenReport',
    defaultMessage: 'Chosen report is broken',
  },
  unknown: {
    id: 'app.components.ErrorComponent.unknown',
    defaultMessage:
      'An unknown error occured. Please, try again later, if the problem persists contact support.',
  },
  refresh: {
    id: 'app.components.ErrorComponent.refresh',
    defaultMessage: 'Try Again',
  },
  goBackOrTryAgain: {
    id: 'app.components.ErrorComponent.goBackOrTryAgain',
    defaultMessage: 'Please go back to previous page or try again.',
  },
  error: {
    id: 'app.components.ErrorComponent.error',
    defaultMessage: 'Something went wrong, please try again.',
  },
  voucherError: {
    id: 'app.components.ErrorComponent.voucherError',
    defaultMessage: 'Run integration again with fixed vouchers.',
  },
  reportError: {
    id: 'app.components.ErrorComponent.reportError',
    defaultMessage:
      "Could not find any company information from this report's configuration. Possibly all companies or budgets selected to this report have been removed or don't exist. Please edit report to fix this error.",
  },
})
